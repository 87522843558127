/**
 * The Brixx class.
 */
class Brixx {

    /**
     * Default settings.
     *
     * These values are provided in case PHP does not generate
     * JS settings.
     *
     * @type {object}
     */
    static defaultSettings = {
        // Default timeout for Ajax calls.
        ajaxTimeout: 30000,
        // Actually retry on 'timeout' only.
        ajaxRetryOmitStatus: [
            'success',
            'parsererror',
            'error',
            'notmodified',
            'abort'
        ],
        // Number of retries for an Ajax call, before it fails.
        ajaxRetries: 3,
        // Time to wait before triggering a new retry.
        ajaxRetriesInterval: 1000,
        // Currency sum decimals modulus divisor.
        currencyDecimalsMod: 5,
        // The theme path to icon files.
        // @todo Use the `asset.packages.moduleicons.base_path` configuration value.
        iconsPath: '/bundles/brixx/img/moduleicons/',
        // Default Noty configuration.
        notyConfig: {
            type: 'info',
            layout: 'topRight',
            timeout: 3000,
            progressBar: true,
            closeWith: ['button']
        },
        dateFormat: 'dd.mm.yyyy'
    };

    /**
     * BRIXX JS app settings.
     *
     * These settings allow for PHP induced settings in a JSON script tag
     * with the `data-brixx-selector="brixx-settings-json"` data attribute.
     *
     * @tutorial brixx-ui-settings
     *
     * @property {string} language
     *   Two-letter locale string representing the document language.
     *   Defaults to `'de'`.
     * @property {string} locale
     *   Locale string with country identifier.
     *   Defaults to `'de-CH'`.
     * @property {number} ajaxTimeout
     *   Timeout for Ajax calls in milliseconds.
     *   Defaults to `30000`.
     * @property {number} ajaxRetries
     *   Number of automatic Ajax call retries.
     *   Defaults to `3`.
     * @property {Array<String>} ajaxRetryOmitStatus
     *   Status texts that will cause omitting Ajax retries.
     *   Defaults to `['success','parsererror','error','notmodified','abort']`.
     * @property {number} ajaxRetriesInterval
     *   Time to wait before triggering a new retry in milliseconds.
     *   Defaults to `10000`.
     * @property {number} currencyDecimalsMod
     *   Currency sum decimals modulus divisor. Used to round decimals of
     *   currency sums to multiples of 5 centimes.
     *   See {@link brixxUtils.numberRound}.
     * @property {object} notyConfig
     *   Noty dismissable notifications plugin configuration.
     * @property {string} notyConfig.type
     *   Notification type.
     *   Defaults to `'info'`.
     * @property {string} notyConfig.layout
     *   Placement of the notification.
     *   Defaults to `'topRight'`.
     * @property {number} notyConfig.timeout
     *   Timout in milliseconds until notifications dismiss automatically.
     *   Defaults to `3000`.
     * @property {boolean} notyConfig.progressbar
     *   Whether to show a progress bar indicating the remaining time
     *   to notification dismissal.
     *   Defaults to `true`.
     * @property {string|Array.<String>} notyConfig.closeWith
     *   Method(s) of notification dismissal.
     *   Defaults to `['click','button']`.
     *
     * @type {object}
     */
    settings = {};

    /**
     * Merges the given settings object(s) into the BRIXX settings.
     *
     * @param  {...any} settingsObjects
     *   Settings object(s) to merge into the BRIXX settings.
     *
     * @return {object}
     *   The resulting BRIXX settings.
     */
    addSettings(...settingsObjects) {

        /**
         * Helper function to determine, whether the given value is an object.
         *
         * @param {*} value
         *   The value to check.
         *
         * @return {bool}
         *   `true`, if the value is an object, `false` otherwise.
         */
        const isObject = value => value && typeof value === 'object' && !Array.isArray(value) && value !== null;

        /**
        * Helper function to deep merge settings objects.
        *
        * This function creates an immutable combination of the given source objects,
        * but does not merge arrays.
        *
        * @param  {...any} objects
        *   Settings objects.
        *
        * @return {object}
        *   The combined settings objects.
        */
        const mergeDeep = (...objects) => objects.reduce((previous, current) => {
            Object.keys(current).forEach(key => {
                const previousValue = previous[key];
                const currentValue = current[key];

                if (isObject(previousValue) && isObject(currentValue)) {
                    previous[key] = mergeDeep(previousValue, currentValue);
                }
                else {
                    previous[key] = currentValue;
                }
            });

            return previous;
        }, {});

        // Merge given settings objects.
        const settingsObject = settingsObjects.reduce((prev, obj) => mergeDeep(prev, obj));
        Object.keys(settingsObject).forEach(key => {
            const oldValue = this.settings[key];
            const newValue = settingsObject[key];

            if (isObject(oldValue) && isObject(newValue)) {
                this.settings[key] = mergeDeep(oldValue, newValue);
            }
            else {
                this.settings[key] = newValue;
            }
        });

        return this.settings;
    }

    /**
     * Checks, whether the given setting value exists.
     *
     * @param {string} settingPath
     *   Namespaced path of the setting.
     *
     * @return {boolean}
     *   `true`, if the setting value exists, `false` otherwise.
     */
    hasSetting(settingPath) {
        const namespaceParts = (settingPath || '').toString().split('.');
        if (namespaceParts.length === 0) {
            return false;
        }
        let parent = this.settings;
        for (let property of namespaceParts) {
            if (!Object.prototype.hasOwnProperty.call(parent, property)) {
                return false;
            }
            parent = parent[property];
        }
        // This check should be obsolete.
        if (typeof parent === 'undefined') {
            return false;
        }
        return true;
    }

    /**
     * Returns a setting value by path.
     *
     * @param {string} settingPath
     *   Namespaced path of the setting.
     * @param {*} [defaultValue]
     *   Default value to return, if the setting value has not been set.
     *
     * @return {*}
     *   The value of the setting, or void, if the value doesn't exist.
     */
    getSetting(settingPath, defaultValue) {
        const namespaceParts = (settingPath || '').toString().split('.');
        if (namespaceParts.length === 0) {
            return defaultValue;
        }
        let parent = this.settings;
        for (let property of namespaceParts) {
            if (!Object.prototype.hasOwnProperty.call(parent, property)) {
                return defaultValue;
            }
            parent = parent[property];
        }
        return parent;
    }

    /**
     * Constructs a Brixx instance.
     */
    constructor() {
        this.modules = {};

        // Add the document language to the default settings.
        if (typeof window.uiLocale !== 'undefined') {
            Brixx.defaultSettings.language = window.uiLocale;
        }
        else if (document.documentElement.getAttribute('lang')) {
            Brixx.defaultSettings.language = document.documentElement.getAttribute('lang');
        }
        else {
            Brixx.defaultSettings.language = 'de';
        }

        Brixx.defaultSettings.locale = (Brixx.defaultSettings.language === 'de') ? 'de-CH' : Brixx.defaultSettings.language;

        this.addSettings(Brixx.defaultSettings);

        // Use direct child elements to harden against XSS exploits when CSP
        // is on.
        const settingsElement = document.querySelector(
            'head > script[type="application/json"][data-brixx-selector="brixx-settings-json"], body > script[type="application/json"][data-brixx-selector="brixx-settings-json"]'
        );

        // Parse JSON provided by PHP.
        if (settingsElement !== null) {
            const appSettings = JSON.parse(settingsElement.textContent);
            this.addSettings(appSettings);
        }
    }

    /**
     * Holds all initialization methods.
     *
     * @namespace Brixx.modules
     * @type {object.<string, Brixx~module>}
     * @tutorial brixx-ui-modules
     */

    /**
     * @typedef {object} Brixx~ModulesInitError
     *
     * @prop {string} name
     *   The error name. May be a custom name, a generic `ModulesInitError`, or
     *   one of the JS standard errors:
     *   - `EvalError`: An error has occurred in the eval() function. (Deprecated. Use `SyntaxError` instead.)
     *   - `RangeError`: A number "out of range" has occurred.
     *   - `ReferenceError`: An illegal reference has occurred.
     *   - `SyntaxError`: A syntax error has occurred.
     *   - `TypeError`: A type error has occurred.
     *   - `URIError`: An error in `encodeURI()` has occurred.
     * @prop {string} message
     *   The error message.
     */

    /**
     * Helper to rethrow errors asynchronously.
     *
     * This custom error is thrown after attach/detach, if one or more
     * modules' attachment or detachment failed.
     *
     * This way errors bubble up outside of the original callstack,
     * making it easier to debug errors in the browser.
     *
     * @param {Error|string} error
     *   The error to be (re-)thrown.
     */
    static throwError(error) {
        setTimeout(() => {
            console.log(error);
            throw Object.prototype.hasOwnProperty.call(error, 'name') && Object.prototype.hasOwnProperty.call(error, 'message') ? error : {
                name: 'ModulesInitError',
                message: error.toString()
            };
        }, 0);
    }

    /**
     * Initializes BRIXX UI Modules.
     *
     * This callback will run on page load and after DOM changes.
     *
     * @callback Brixx~modulesAttach
     *
     * @param {HTMLDocument|HTMLElement|jQuery} context
     *   An element to attach modules init to.
     * @param {object} settings
     *   An object containing settings for the current context.
     *
     * @tutorial brixx-ui-modules
     * @see Brixx.attachModules
     */

    /**
     * Reverts and cleans up BRIXX UI Module initializations.
     *
     * This callback may run when content is about to be
     * serialized, removed from or moved within the DOM.
     *
     * @callback Brixx~modulesDetach
     *
     * @param {HTMLDocument|HTMLElement|jQuery} context
     *   An element to attach modules init to.
     * @param {object} settings
     *   An object containing settings for the current context.
     * @param {string} trigger
     *   One of `'unload'`, `'move'`, or `'serialize'`.
     *
     * @tutorial brixx-ui-modules
     * @see Brixx.detachModules
     */

    /**
     * @typedef {object} Brixx~module
     *
     * @prop {Brixx~modulesAttach} attach
     *   Function run on page load and after DOM changes.
     * @prop {Brixx~modulesDetach} detach
     *   Function run when content is about to be serialized,
     *   removed from or moved within the DOM.
     *
     * @tutorial brixx-ui-modules
     */

    /**
     * Runs all registered BRIXX UI Module `attach` handlers.
     *
     * BRIXX UI Modules are registered in the {@link Brixx.modules} object and
     * may provide `attach` handlers and optionally also `detach` handlers that
     * allow them to attach or detach event handlers to or from page elements.
     *
     * {@link Brixx.attachModules} runs on initial page load. Developers
     * implementing Ajax or dynamically adding elements to the DOM should also
     * call this function after new page content has been created, feeding in the
     * new elements to be processed, in order to allow modules to attach to the
     * new content.
     *
     * Modules should use the jQuery-Once plugin during attachment, to ensure
     * that event bindings are attached only once to a given element:
     * `$(context).find(selector).once('init-name').on('click', event => { [...] });`
     *
     * Doing so enables the reprocessing of given elements, which may be needed
     * during a pages' life-time cycle.
     *
     * @example
     * Brixx.modules.moduleName = {
     *     attach: function (context, settings) {
     *         // ...
     *     },
     *     detach: function (context, settings, trigger) {
     *         // ...
     *     }
     * };
     *
     * @param {HTMLDocument|HTMLElement|jQuery} [context=document]
     *   The wrapper of elements to attach to.
     * @param {object} settings
     *   An object containing settings for the current context. If none is given,
     *   the {@link Brixx.settings} object is used.
     *
     * @throws {Brixx~ModulesInitError}
     *
     * @tutorial brixx-ui-modules
     * @see Brixx~modulesAttach
     * @see Brixx.detachModules
     *
     * @memberof Brixx
     * @method attachModules
     */
    attachModules(context, settings) {
        context = context || document;
        settings = settings || this.settings;
        const modules = this.modules;
        // Execute all of them.
        Object.keys(modules || {}).forEach(i => {
            if (typeof modules[i].attach === 'function') {
                // Don't stop the execution of init in case of an error.
                try {
                    modules[i].attach(context, settings);
                }
                catch (e) {
                    Brixx.throwError(e);
                }
            }
        });
    }

    /**
     * Runs all registered BRIXX UI Module `detach` handlers.
     *
     * Developers should call this function before page content is about to
     * be removed from or moved within the DOM, feeding in the wrapping
     * element of the content to be altered, in order to allow modules to
     * detach from it.
     *
     * Such implementations should use `.findOnce()` and `.removeOnce()` to find
     * elements with their corresponding `Brixx.modules.moduleName.attach`
     * implementation, i.e. `.removeOnce('moduleName')`, to ensure the module
     * is detached only from previously processed elements.
     *
     * @param {HTMLDocument|HTMLElement|jQuery} [context=document]
     *   Wrapping element of content to detach modules from.
     * @param {object} settings
     *   An object containing settings for the current context. If none given,
     *   the {@link Brixx.settings} object is used.
     * @param {string} [trigger='unload']
     *   A string containing what's causing the module to be detached. The
     *   possible triggers are:
     *   - `'unload'`: The context element is being removed from the DOM.
     *   - `'move'`: The element is about to be moved within the DOM (for example,
     *     during a jQuery UI drag'n'drop). After the move is completed,
     *     {@link Brixx.attachModules} is called, so that the init can undo
     *     whatever it did in response to the move. Many modules won't need to
     *     do anything simply in response to the element being moved, but because
     *     IFRAME elements reload their "src" when being moved within the DOM,
     *     init bound to IFRAME elements (like WYSIWYG editors) may need to
     *     take some action.
     *   - `'serialize'`: When an Ajax form is submitted, this is called with the
     *     form as the context. This provides every module attachment within the
     *     form an opportunity to ensure that the field elements have correct
     *     content in them before the form is serialized. The canonical use-case
     *     is so that WYSIWYG editors can update the hidden textarea to which they
     *     are bound.
     *
     * @throws {Brixx~ModulesInitError}
     *
     * @tutorial brixx-ui-modules
     * @see Brixx~modulesDetach
     * @see Brixx.attachModules
     *
     * @memberof Brixx
     * @method detachModules
     */
    detachModules(context, settings, trigger) {
        context = context || document;
        settings = settings || this.settings;
        trigger = trigger || 'unload';
        const modules = this.modules;
        // Execute all of them.
        Object.keys(modules || {}).forEach(i => {
            if (typeof modules[i].detach === 'function') {
                // Don't stop the execution of detach in case of an error.
                try {
                    modules[i].detach(context, settings, trigger);
                }
                catch (e) {
                    Brixx.throwError(e);
                }
            }
        });
    }

}

// The Brixx object should be available globally for all
// modules.
if (typeof window.Brixx === 'undefined') {

    /**
     * Global Brixx object.
     *
     * Holds init methods for all modules that should run on
     * page load (or before and after the DOM has been altered)
     * within its `{@link Brixx.modules}` namespace.
     *
     * Additional namespaces and functions may be added by various
     * components of the BRIXX UI.
     *
     * @global
     * @namespace Brixx
     * @type {Brixx}
     */
    window.Brixx = new Brixx();

}

export default window.Brixx;
